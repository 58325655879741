import React, { useEffect, useState } from "react";
import { Table, Button, Space, Modal, message } from "antd";
import { getUsers } from "../services/apiService";
import UserDetails from "./UserDetails";
import CreateUser from "./CreateUser";

const UserList: React.FC = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [isUserDetailsVisible, setUserDetailsVisible] = useState(false);
  const [isCreateUserVisible, setCreateUserVisible] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response: any = await getUsers();
      console.log(response, "getuser------");
      if (response?.data?.status && Array.isArray(response?.data?.result)) {
        setUsers(response.data.result);
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch users.");
    }
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Mobile No", dataIndex: "mobileNo", key: "mobileNo" },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => <Space size="middle"></Space>,
    },
  ];

  return (
    <div>
      <Button type="primary" onClick={() => setCreateUserVisible(true)}>
        Create User
      </Button>

      <Table
        columns={columns}
        dataSource={users}
        rowKey="id"
        style={{ marginTop: 20 }}
      />

      <Modal
        title="User Details"
        visible={isUserDetailsVisible}
        onCancel={() => setUserDetailsVisible(false)}
        footer={null}
      >
        {selectedUser && <UserDetails />}
      </Modal>

      <Modal
        title="Create User"
        visible={isCreateUserVisible}
        onCancel={() => setCreateUserVisible(false)}
        footer={null}
      >
        <CreateUser
          onCreate={() => {
            fetchUsers();
            setCreateUserVisible(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default UserList;
