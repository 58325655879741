import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Select } from "antd";
import { createUser, getRoles } from "../services/apiService";
const { Option } = Select;

const CreateUser: React.FC<{ onCreate: () => void }> = ({ onCreate }) => {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<any[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await getRoles();
        if (Array.isArray(response.data?.result)) {
          setRoles(response.data.result);
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        message.error("Failed to fetch roles.");
      }
    };
    fetchRoles();
  }, []);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      const userData = {
        ...values,
        platform: "GROYYO_PLUS",
      };
      await createUser(userData);
      message.success("User created successfully.");
      onCreate();
    } catch (error) {
      message.error("Failed to create user.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input the name!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Email" name="email">
        <Input />
      </Form.Item>
      <Form.Item
        label="Mobile No"
        name="mobileNo"
        rules={[{ required: true, message: "Please input the mobile number!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Role"
        name="roleId"
        rules={[{ required: true, message: "Please select a role!" }]}
      >
        <Select placeholder="Select a role">
          {roles.map((role: any) => (
            <Option key={role.id} value={role.id}>
              {role.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={loading} block>
        Create
      </Button>
    </Form>
  );
};

export default CreateUser;
