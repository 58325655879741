import React, { useEffect, useState } from "react";
import { Button, Layout, Avatar, Dropdown, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, removeToken } from "../services/apiService";

const { Header: AntHeader } = Layout;

const Header: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const userDetails = localStorage.getItem("userDetails");
    console.log(userDetails, "userDetails");
    if (userDetails) {
      try {
        const parsedUserDetails = JSON.parse(userDetails);
        const finalUserDetails =
          typeof parsedUserDetails === "string"
            ? JSON.parse(parsedUserDetails)
            : parsedUserDetails;
        console.log(parsedUserDetails, "parsedUserDetails");
        console.log(finalUserDetails, "finalUserDetails");
        setUser(finalUserDetails);
      } catch (error) {
        console.error("Failed to parse user details:", error);
      }
    }
  }, []);

  const handleLogout = () => {
    removeToken();
    localStorage.removeItem("userDetails");
    navigate("/login");
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <span>{user?.email}</span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2">{user?.organization}</Menu.Item>
      <Menu.Divider />
      <Menu.ItemGroup title="Factories">
        {user?.factory?.map((factory: any) => (
          <Menu.Item key={factory.id}>{factory.factoryName}</Menu.Item>
        ))}
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.Item key="3" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <AntHeader
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 20px",
        backgroundColor: "#001529",
        color: "#fff",
      }}
    >
      <h1 style={{ color: "#fff" }}>Org:{user?.organization}</h1>
      <div style={{ display: "flex", alignItems: "center" }}>
        {isAuthenticated() ? (
          <>
            <Dropdown overlay={menu} placement="bottomRight">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Avatar
                  style={{ backgroundColor: "#87d068" }}
                  icon={<UserOutlined />}
                />
                <span style={{ color: "#fff", marginLeft: 8 }}>
                  {user?.name || "User"}
                </span>
              </div>
            </Dropdown>
          </>
        ) : (
          <Button type="primary" onClick={handleLogin}>
            Login
          </Button>
        )}
      </div>
    </AntHeader>
  );
};

export default Header;
