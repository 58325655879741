// src/components/Login.tsx
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Alert } from "antd";
import { generateOtp, login, setToken } from "../services/apiService";
import { v4 as uuidv4 } from "uuid";

const Login: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [deviceId, setDeviceId] = useState<string>("");
  const [fcmToken, setFcmToken] = useState<string>("mock-fcm-token");
  const navigate = useNavigate();

  useEffect(() => {
    setDeviceId(uuidv4());
  }, []);

  const handleSendOtp = async () => {
    try {
      await generateOtp(phoneNumber, "GROYYO_PLUS");
      setIsOtpSent(true);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage("Failed to send OTP. Please try again.");
    }
  };

  const handleLogin = async () => {
    try {
      const response = await login({
        mobileNo: phoneNumber,
        otpCode: otp,
        fcmToken: fcmToken,
        deviceId: deviceId,
        platform: "GROYYO_PLUS",
      });
      console.log(response, "response----");
      setToken(response?.data?.result?.token);
      localStorage.setItem(
        "userDetails",
        JSON.stringify(response?.data?.result)
      );
      navigate("/");
    } catch (error) {
      setErrorMessage("Invalid OTP. Please try again.");
    }
  };

  return (
    <div style={{ maxWidth: "400px", margin: "100px auto" }}>
      <h2>Login</h2>
      {errorMessage && (
        <Alert
          message={errorMessage}
          type="error"
          showIcon
          style={{ marginBottom: "20px" }}
        />
      )}
      <Form layout="vertical">
        <Form.Item label="Phone Number">
          <Input
            type="text"
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            disabled={isOtpSent}
          />
        </Form.Item>
        {isOtpSent && (
          <Form.Item label="OTP">
            <Input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </Form.Item>
        )}
        {!isOtpSent ? (
          <Button type="primary" onClick={handleSendOtp} block>
            Send OTP
          </Button>
        ) : (
          <Button type="primary" onClick={handleLogin} block>
            Login
          </Button>
        )}
      </Form>
    </div>
  );
};

export default Login;
