import React from "react";
import { Layout } from "antd";

const { Footer: AntFooter } = Layout;

const Footer: React.FC = () => {
  return (
    <AntFooter style={{ textAlign: "center" }}>Groyyo Plus ©2024</AntFooter>
  );
};

export default Footer;
