import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const generateOtp = async (mobileNo: string, platform: string) => {
  return api.get("/login/generate-otp", {
    params: { mobileNo, platform },
  });
};

export const login = async (payload: {
  mobileNo: string;
  otpCode: string;
  fcmToken: string;
  deviceId: string;
  platform: string;
}) => {
  return api.post("/login/login", payload);
};

export const signup = async (signupData: any) => {
  return api.post("/login/signup", signupData);
};

export const logout = async (
  mobileNo: string,
  fcmToken: string,
  deviceId: string
) => {
  return api.post("/login/logout", {
    mobileNo,
    fcmToken,
    deviceId,
  });
};

export const getUsers = async () => {
  return api.get("/user");
};

export const getSoftDeletedUsers = async () => {
  return api.get("/user/soft-deleted");
};

export const createUser = async (userData: any) => {
  return api.post("/user/create-user", userData);
};

export const getUserDetail = async (userId: number) => {
  return api.get(`/user/${userId}/detail`);
};

export const softDeleteUser = async (userId: number) => {
  return api.delete(`/user/soft-delete/${userId}`);
};

export const restoreSoftDeletedUsers = async (ids: number[]) => {
  return api.patch("/user/restore", { ids });
};

export const setToken = (token: string) => {
  localStorage.setItem("authToken", token);
};

export const getToken = () => {
  return localStorage.getItem("authToken");
};

export const removeToken = () => {
  localStorage.removeItem("authToken");
};

export const isAuthenticated = () => {
  return !!getToken();
};

export const getRoles = async () => {
  return api.get("/role");
};
