import React from "react";

const Dashboard: React.FC = () => {
  return (
    <div style={{ padding: "20px" }}>
      <h2>Welcome to the Dashboard!</h2>
    </div>
  );
};

export default Dashboard;
