import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Layout } from "antd";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import Login from "./pages/Login";
import ProtectedRoute from "./pages/ProtectedRoute";

import UserList from "./pages/UserList";
import CreateUser from "./pages/CreateUser";
import UserDetails from "./pages/UserDetails";
import Dashboard from "./pages/Dashboard";
const { Content } = Layout;

const AppContent: React.FC = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  console.log(process.env.REACT_APP_API_BASE_URL, "envlink---");
  console.log(process.env.REACT_APP_ENV, "env---");
  return (
    <Layout style={{ minHeight: "100vh" }}>
      {!isLoginPage && <Sidebar />}
      <Layout
        className="site-layout"
        style={{ marginLeft: isLoginPage ? "0" : "" }}
      >
        <Header />
        <Content style={{ padding: "20px" }}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users"
              element={
                <ProtectedRoute>
                  <UserList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user/create"
              element={
                <ProtectedRoute>
                  <CreateUser onCreate={() => {}} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user/:id/detail"
              element={
                <ProtectedRoute>
                  <UserDetails />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
